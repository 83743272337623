// import logo from "./logo.svg";
import "./App.css";
import "./components/footer";

import React, { useEffect, useState, useMemo, useRef } from "react";
import Axios from "axios";

import { Footer } from "./components/footer";
import { NavBar } from "./components/navbar";
import { Loading } from "./components/loading";
import { Login } from "./components/login";
import { Contact } from "./components/contact";
import { Home } from "./components/home";
import { Feedback } from "./components/feedback";

import { Services } from "./components/services";
import { Products } from "./components/products";
import { OurWorks } from "./components/works";
import { AboutUs } from "./components/aboutus";
 
import { Default } from "./components/default";
import { ServiceDetails } from "./components/servicedetails";

import {WhatsNew} from "./components/whatsnew";
import {SingleArticle} from "./components/SingleArticle"
import {OurTeam} from "./components/ourteam";

import {StudentDetails} from "./components/StudentDetails";

import { Dash } from "./components/dash";
import { UserContext } from "./components/UserContext";
import {
  createMuiTheme,
  ThemeProvider,
  MuiThemeProvider,
  Fab,
  Zoom,
  useScrollTrigger,
  makeStyles,
} from "@material-ui/core";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { HelmetProvider, Helmet } from "react-helmet-async";
// import "@fontsource/poppins"; 

const headKey = "qweqeasdj123n1jasndjn2j3njsdn12i891bdjsdu1283djsd1231231";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4c004c",
      // main: "#333333",
    },
    secondary: {
      // main: "#800000",
      main: "#4c004c",
    },
    white: {
      main: "#000000",
    },
    cream: {
      main: "#f2f0f0",
    },
  },
  typography: {
    fontFamily: "Poppins",
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(7),
  },
}));

function ScrollTop(props) {
  const { children, window, topRef } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    
    // const anchor = (event.target.ownerDocument || document).querySelector(
    //   "#back-to-top-anchor"
    // );

    // if (anchor) {
    //   anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    // }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

const App = (props) => {
  const [isloading, setIsloading] = useState(true);
  const [user, setUser] = useState();

  const topOfPageRef = useRef(null);

  const value = useMemo(() => ({ user, setUser }), [user, setUser]);

  useEffect(() => {
    Axios.get("/api/checksession", {
      headers: { headKey: headKey },
    }).then((res) => {
      if (res.data.status) {
        setUser(res.data.userdata);
        setIsloading(false);
      } else {
        setUser(null);
        setIsloading(false);
      }
    });
  }, []);

  return (
    <HelmetProvider>
      <MuiThemeProvider theme={theme}>
        <div className="App" style={{fontFamily:"Poppins !important"}} ref={topOfPageRef}>
          {isloading ? (
            <Loading />
          ) : (
            <UserContext.Provider value={value}>
              <div className="page-container">
                <Router>
                  <NavBar />
                  <div class="content-wrap">
                    <Routes>
                      <Route
                        path="/login_ad"
                        element={user ? <Navigate to="/dash" /> : <Login />}
                      />
                      <Route path="/" element={<Home />} />
                      <Route
                        path="/dash/*"
                        element={user ? <Dash /> : <Navigate to="/" />}
                      />
                      <Route path="/get-in-touch" element={<Contact />} />
                      <Route path="/our-services" element={<Services />} />
                      <Route path="/our-products" element={<Products />} />
                      <Route path="/our-team" element={<OurTeam />} />
                      <Route path="/about-us" element={<AboutUs />} />
                      <Route path="/whats-new" element={<WhatsNew />} />
                      <Route path="/our-team" element={<OurTeam />} />

                      <Route path="/student-details" element={<StudentDetails />} />
                      <Route path="/article-details/:slug/:id" element={<SingleArticle />} />
                      <Route
                        path="/service-details/:name/:id"
                        element={<ServiceDetails />}
                      />
                      <Route
                        path="/give-your-feedback"
                        element={<Feedback />}
                      />

                      <Route
                        path="/dash"
                        element={user ? <Dash /> : <Navigate to="/login_ad" />}
                      />
                      <Route path="*" element={<Navigate to="/" />}/>
                    </Routes>
                  </div>

                  <Footer />
                  <ScrollTop {...props} topRef={topOfPageRef}>
                    <Fab
                      color="primary"
                      aria-label="scroll back to top"
                      style={{ border: "1px solid white" }}
                    >
                      <KeyboardArrowUpIcon />
                    </Fab>
                  </ScrollTop>
                </Router>
              </div>
            </UserContext.Provider>
          )}
        </div>
      </MuiThemeProvider>
    </HelmetProvider>
  );
};

export default App;
